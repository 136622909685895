<template>
  <div>
    <div class="pt-48 pb-130p flex justify-center xl:justify-between px-32 max-w-content px-6 sm:m-auto min-h-screen">
      <div class="text-left">
        <p class="text-neutral/400 text-100 font-bold">ERROR</p>
        <p class="text-navy text-180 sm:text-218 font-bold">404</p>
        <p class="text-neutral/500 text-18">Ooops...... we couldn’t find the page you are looking for.</p>
        <p class="text-neutral/500 text-18">But don’t worry, we will fix it soon.</p>

        <button  @click="$router.go(-2)" class="mt-60p hover:bg-primary1 text-black font-normal text-18 p-3 rounded-full cursor-pointer bg-white border hover:border-transparent border-primary1 px-12">
          Back
        </button>
      </div>

      <img class="h-480p hidden xl:block" src="@/assets/image404.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>
