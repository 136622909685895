<template>
  <div class="py-80p md:py-150p bg-white relative pb-12 px-6">

    <div class="text-24 md:text-32 text-navy font-bold mb-80p text-center flex flex-col justify-center items-center">
      <div class="mb-2 ">
        <gradient-line/>
      </div>
      <slot name="title"/>

      <p class="text-16 md:text-18 text-black opacity-50 font-normal text-center mt-3">
        <slot name="subtitle"/>
      </p>
    </div>

      <slot/>

  </div>
</template>

<script>
import GradientLine from '@/components/UI_components/GradientLine'
export default {
  name: 'SectionLayout',
  components: { GradientLine }
}
</script>
