<template>
    <button class="
              whitespace-nowrap
              text-black text-18
              flex
              items-center
              gap-2
              font-normal
              py-3
              rounded-full
              cursor-pointer
              border border-primary1
            "
            :class="styles"
            @click="clickHandler"
            :disabled="isDisabled"
            type="submit"
    >
      <div v-if="arrow" class="bg-button-arrow bg-no-repeat bg-contain w-10 h-10 md:hidden"/>
      <slot/>
    </button>
</template>

<script>
export default {
  name: 'CustomButton'
}
</script>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
const emit = defineEmits()

const props = defineProps({
  type: {
    type: String,
    default: 'primary'
  },
  arrow: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})

const styles = computed(() => {
  let stylesStr = ''
  let hoverStyle = ''

  switch (props.type) {
    case 'primary':
      stylesStr += 'bg-primary1 '
      hoverStyle = 'hover:bg-white'
      break
    case 'secondary':
      stylesStr += 'bg-white '
      hoverStyle = 'hover:bg-primary1'
      break
  }

  if (props.arrow) {
    stylesStr += 'px-3 md:px-12 '
  } else {
    stylesStr += 'px-12 '
  }

  if (props.isDisabled) {
    stylesStr += 'opacity-50 cursor-not-allowed'
  } else {
    stylesStr += `${hoverStyle}`
  }

  return stylesStr
})

function clickHandler() {
  emit('buttonClick')
}

</script>
