<template>
  <div class="pt-20 pb-12 px-9 w-full sm:w-300p lg:w-488p h-455p rounded-45p border cursor-pointer flex flex-col justify-between hover:shadow-4xl">
    <div class="flex justify-center">
      <slot name="logo"/>
    </div>

    <div>
      <h6 class="text-navy font-bold text-24 text-left">
        <slot name="brand"/>
      </h6>
      <p class="text-neutral/500 mt-2 text-left">
        <slot name="short_description"/>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsItem'
}
</script>
