<template>
	<div
			v-if="isTop"
			@click="scrollToTop()"
			class="fixed bottom-10 right-10 cursor-pointer w-10 h-10 shadow-4xl"
	>
		<img src="@/assets/icons/up-arrow.svg">
	</div>
</template>

<script>
    export default {
        name: "ScrollToTop"
    }
</script>

<script setup>
	import { ref, onMounted } from 'vue'
	const isTop = ref(false)

  const scrollToTop = () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
  }

  onMounted(() => {
      window.addEventListener('scroll', () => {
          let scrollPos = window.scrollY
          if(scrollPos >= 500){
              isTop.value = true
          } else {
              isTop.value = false
          }
      })
  })
</script>
