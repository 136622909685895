<template>
  <navbar></navbar>
  <div class="w-full h-full">
    <router-view/>
  </div>
  <Footer v-if="!$router.currentRoute.value.meta.hideFooter"></Footer>
  <scroll-to-top/>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import AOS from 'aos'
import ScrollToTop from './components/ScrollToTop'

export default {
  components: { Navbar, Footer, ScrollToTop },
  mounted() {
    AOS.init()
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Montserrat', sans-serif;;
}
</style>
