<template>
  <div
    class="w-full flex justify-center pt-36 relative xl:px-12 2xl:px-24 px-4 relative"
    :class="{'bg-navy': $router.currentRoute.value.meta.background !=='transparent'}"
  >
    <footer-head v-if="$router.currentRoute.value.path ==='/'" @buttonClick="$router.push('careers')">
      <template #title>
        Wanna grow with our team?
      </template>

      <template #button-label >
          Join us
      </template>
    </footer-head>

    <footer-head
			v-else-if="$router.currentRoute.value.path === '/services' || $router.currentRoute.value.path.includes('/project-info')"
			@buttonClick="$router.push('/contact-us')"
    >
      <template #title>
        Let’s talk about your project
      </template>

      <template #button-label>
          Contact us
      </template>
    </footer-head>

    <footer-head v-else-if="$router.currentRoute.value.path==='/about'" @buttonClick="$router.push('/contact-us')">
      <template #title>
        Let’s discuss your business needs
      </template>

      <template #button-label>
          Let's talk
      </template>
    </footer-head>

    <footer-head v-else-if="$router.currentRoute.value.path ==='/contact-us'" @buttonClick="$router.push('careers')">
      <template #title>
        Interested in job opportunities?
      </template>

      <template #button-label>
          See Vacanciess
      </template>
    </footer-head>

    <footer-head v-else-if="$router.currentRoute.value.path ==='/projects'" @buttonClick="$router.push('contact-us')">
      <template #title>
        Let’s talk about your project
      </template>

      <template #button-label>
          Contact Us
      </template>
    </footer-head>

    <footer-head v-else-if="$router.currentRoute.value.path ==='/careers'" @buttonClick="$router.push('contact-us')">
      <template #title>
        Have something else in mind?
      </template>

      <template #button-label>
          Let’s Talk
      </template>
    </footer-head>

    <div class="w-full flex justify-start 2xl:gap-16 gap-4 lg:flex-row flex-col-reverse">
      <div class="lg:w-1/4 w-full flex flex-col sm:items-center lg:items-start lg:pt-24 pt-10">
        <div class="flex flex-col sm:flex-row lg:flex-col justify-center sm:justify-between lg:justify-start gap-4 lg:gap-7">

          <div class="w-full flex justify-center sm:justify-start items-center lg:gap-2 gap-2 text-14 text-white">
            <div class="w-5 h-5 bg-email bg-no-repeat"/>
            <div>hr@dthreads.am</div>
          </div>

          <div class="w-full flex justify-center sm:justify-start items-center gap-2 text-14 text-white">
            <div class="w-5 h-5 bg-location bg-no-repeat"/>
            <div>Abelyan Street, 6/4, Yerevan, Armenia</div>
          </div>

          <div class="w-full flex justify-center sm:justify-start items-center gap-2 text-14 text-white">
            <div class="w-5 h-5 bg-phone bg-no-repeat"></div>
            <div>{{ sitePhoneNumber }}</div>
          </div>

        </div>

        <div class="flex justify-center items-center sm:justify-start gap-5 lg:mt-10 mt-6">
          <a href="https://www.facebook.com/digit.threads" target="_blank" class="block">
            <span class="block w-6 h-6 bg-facebook bg-no-repeat cursor-pointer"/>
          </a>

          <a href="https://www.linkedin.com/company/digit-threads/?viewAsMember=true" target="_blank" class="block">
            <span class="block w-6 h-6 bg-linkedin bg-no-repeat cursor-pointer"/>
          </a>

<!--          <a href="#" target="_blank" class="block">-->
<!--            <div class="w-6 h-6 bg-instagram bg-no-repeat cursor-pointer"/>-->
<!--          </a>-->
        </div>
      </div>

      <div class="lg:w-2/4 w-full flex flex-col items-center gap-8">
        <div class="w-full flex justify-center">
          <router-link to="/">
            <div class="w-28 h-10 bg-logo bg-no-repeat bg-cover"></div>
          </router-link>
        </div>
        <div class="w-full flex justify-center items-center sm:items-start lg:gap-8 gap-4 sm:flex-row flex-col" id="nav">
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/">Home</router-link>
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/about">About us</router-link>
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/services">Services</router-link>
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/projects">Projects</router-link>
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/careers">Careers</router-link>
          <router-link class="text-white text-18 hover:font-bold whitespace-nowrap w-24" to="/contact-us">Contact us</router-link>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      px-4
      text-white
      flex
      flex-col sm:flex-row
      flex-wrap
      gap-2 sm:gap-6
      py-4
      bg-navy
      justify-center
    "
  >
    <router-link v-for="link in underLinks" :key="link.id" :to="link.link" class="underline opacity-70 text-12 sm:text-16 text-center">
      {{ link.label }}
    </router-link>
    <p class="opacity-70 text-12 sm:text-16 text-center">
      © 2022 Copyright E-Sync. All rights reserved.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<script setup>
import { reactive, ref } from 'vue'
import FooterHead from '@/components/Footer/FooterHead'

const underLinks = reactive([
  {
    id: 1,
    label: 'Terms & Conditions',
    link: '/terms_and_conditions'
  },
  {
    id: 2,
    label: 'Privacy Policy',
    link: '/privacy_policy'
  }
])

const sitePhoneNumber = ref(process.env.VUE_APP_SITE_CONTACT_US_PHONE_NUMBER)
</script>

<style scoped>
#nav a:hover {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  font-weight: bold;
}
</style>
