<template>
  <div class="w-full h-full flex flex-col" v-if="currentProject">
    <div class="mt-165p lg:pb-112p">
      <div class="flex justify-center items-center h-265p shadow-4xl z-20 mb-20">
        <img :src="currentProject.image" class="w-367p">
      </div>
    </div>
    <div class="px-50p flex flex-col lg:flex-row gap-12">
      <div class="flex-2 lg:pr-20">
        <h1 class="text-24 font-bold text-navy">
          Brief
        </h1>
        <div class="leading-6 mt-8 text-neutral/400" v-html="currentProject.description"></div>
      </div>
      <div class="flex-1 min-w-350p lg:px-6">
        <h1 class="text-24 font-bold text-navy">
          Technologies
        </h1>
        <div class="flex flex-wrap gap-5 mt-11">
          <span
            v-for="item in currentProject.technologies"
            :key="item"
            class="border border-primary1 rounded-45p px-8 py-1"
          >
            {{ item }}
          </span>
        </div>
      </div>
    </div>

    <Carousel :settings="carouselSettings" class="cursor-pointer mb-100p">
      <Slide v-for="(photo, index) in currentProject.sliderPhotos" :key="index" class="py-50p">
        <div class="carousel__item gap-24">
          <div :class="`bg-${photo}`" class="background-screen bg-no-repeat bg-cover"/>
        </div>
      </Slide>
    </Carousel>

    <div v-if="currentProject.keyResults" class="px-6 sm:px-50p mb-202p mb:mb-265p">
      <h1 class="text-center text-navy text-24 font-bold">
        Key Result
      </h1>
      <div class="mt-20 flex flex-wrap gap-20 justify-center">
        <div v-for="item in currentProject.keyResults" :key="item" class="flex flex-col justify-center items-center rounded-45p shadow-4xl py-8 px-12 max-w-400p">
          <img src="@/assets/icons/keyResultsIcon.svg" class="mt-4 relative">
          <p class="text-18 text-navy text-center mt-8 leading-6">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'ProjectInfo',
}
</script>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import projectsData from '@/store/projects.js'

import { Carousel, Slide } from 'vue3-carousel'

const route = useRoute()
const router = useRouter()

const carouselSettings = reactive({
    itemsToShow: '1.5',
    wrapAround: true,
    autoplay: '4000',
    breakpoints: {
      1536: {
        itemsToShow: '3.95'
      },
      1440: {
        itemsToShow: '2.95'
      },
      1024: {
        itemsToShow: '2.35'
      },
      768: {
        itemsToShow: '1.8'
      },
      640: {
        itemsToShow: '1.5'
      },
      500: {
        itemsToShow: '1.6'
      },
      460: {
        itemsToShow: '1.4'
      },
    }
  })
const currentProject = computed(() => {
  if (route.params.id) {
    return projectInfoData.find(el => el.id.toString() === route.params.id.toString())
  }

  return projectInfoData.find(el => el.id === route.params.id)
})
const projectInfoData = reactive([...projectsData])

onMounted(() => {
  if (!currentProject.value) {
    router.push('/pageNotFound')
  } else {
    document.title = route.meta.title + ' | ' + currentProject.value.brand
  }
})
</script>

<style scoped>
.background-screen {
  width: 367px;
  height: 202px;
}
.carousel__slide {
  padding: 100px 10px
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
  padding: 68px 38px;
}
.carousel__slide--visible > .carousel__item {
  opacity: 0.5;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  opacity: 0.5;
}
.carousel__slide--prev > .carousel__item {
  opacity: 0.5;
}
.carousel__slide--active > .carousel__item {
  position: relative;
  opacity: 1;
  transform: scale(1.1);
}
.carousel__slide--active > .carousel__item:after {
  content: '';
  position: absolute;
  transform: scale(0.9);
  top: 29px;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  width: 100%;
  height: 120%;
  background-image: url("../assets/carousel_wrapper_image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
@media (max-width: 640px) {
  .background-screen {
    width: 206px;
    height: 115px;
  }
  .carousel__slide > .carousel__item {
    padding: 39px 22px;
  }
  .carousel__slide--active > .carousel__item:after {
    top: 16px;
  }
}
</style>
