<template>
  <div class="text-navy w-265p">
    <div class="inline-block m-auto flex justify-center">
      <slot name="icon"/>
    </div>

    <h3 class="uppercase mt-10 text-navy text-24 font-bold text-center">
      <slot name="title"/>
    </h3>

    <p class="mt-3 text-neutral/400 text-center">
      <slot name="description"/>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OurValuesItem'
}
</script>

<style scoped>

</style>
