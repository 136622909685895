const projects = [
        {
            id: 1,
            image: require('@/assets/projects_logos/guruh.svg'),
            logo: require('@/assets/projects_logos/guruh.svg'),
            brand: 'Guruh',
            sliderPhotos:[
                'guruh1',
                'guruh2',
                'guruh3',
                'guruh4',
                'guruh5',
                'guruh6',
                'guruh7',
                'guruh8',
                'guruh9',
                'guruh10',
                'guruh11',
                'guruh12',
                'guruh13',
                'guruh14',
                'guruh15',
                'guruh16',
            ],
            technologies: ['laravel', 'vue js', 'postgresql', 'SQL', 'web sockets'],
            'short-description': 'GURUH is a modular based royalty reporting tool for independent record labels and music publishers. Without unnecessary segments.',
            description: `<div>
                        <p>
                          GURUH is a modular based royalty reporting tool for independent record labels and music publishers.
                          Without unnecessary segments. GURUH makes professional royalty reporting accessible for even the smallest of record labels.
                        </p>
                        <p class="mt-2">
                          App build for independent record labels, artists, managements, songwriters, independent music publishers
                        </p>
                        <p class="mt-2">
                          To use it, the user needs to simply import your data (label copies on track, album, artist or label, physical sales imports are allowed as well) to the system, where it analyzes it and shows reports about spendings and earnings traffic.
                          Generate reports monthly, quarterly, bi-annually or yearly. Provide summarized basic statements to your artists or allow for full detail reports.
                        </p>
                      </div>`,
            keyResults: [
                'We developed smart solutions for data analysis ',
                'We developed smart solutions for data format imports ',
                'We have a platform where users can get a professional spend analysis report.'
            ]
        },
        {
            id: 2,
            image: require('@/assets/projects_logos/jan-van-sundert.svg'),
            logo: require('@/assets/projects_logos/jan-van-sundert.svg'),
            brand: 'Jan Van Sundert',
            sliderPhotos: [
                'JVS9',
                'JVS8',
                'JVS7',
                'JVS6',
                'JVS5',
                'JVS4',
                'JVS3',
                'JVS2',
                'JVS1',
            ],
            technologies: ['laravel', 'vue js', 'postgresql', 'SQL', 'web sockets'],
            'short-description': 'Jan Van Sundert provides interesting design solutions for kitchen, bathroom, floor tiles and saunas and all the above mentioned in digital format.',
            description: `<div>
                        <p>
                          Jan Van Sundert is a 50 years old and has been an integral part of the West Brabant region since 1969.
                          Located in Etten-Leur, where grandfather and grandmother Van Sundert laid the foundation for a healthy family
                          business. There are now more than fifty employees, several generations have worked within the company and the
                          showroom with 5500 m2 has grown to become the largest in the Benelux.
                        </p>
                        <p class="mt-2">
                          Jan Van Sundert provides interesting design solutions for kitchen, bathroom, floor tiles and saunas and all the above mentioned in digital format.
                        </p>
                        <p class="mt-2">
                          For the JVS customers who want to see products and showrooms in a digital format.
                        </p>
                        <p class="mt-2">
                          You just need to visit the website and select the showroom or product you prefer, all the rest of the
                          information can be found on that page. Price, discount price, dimensions, pictures, materials and other
                          details are all collected for user's convenience.
                        </p>
                      </div>`,
            keyResults: [
                'frontend and backend of the provided design with some changes for more convinience.',
                'an admin panel, where admin can manage all the neccesary information of the web page.',
                'The showroom of 5500 m2 in one web page with all the neccesary information.'
            ]
        },
        {
            id: 4,
            image: require('@/assets/projects_logos/servinga.svg'),
            logo: require('@/assets/projects_logos/servinga.svg'),
            brand: 'Servinga',
            sliderPhotos: [
                'servinga1',
                'servinga2',
                'servinga3',
                'servinga4',
            ],
            technologies: ['laravel','MySQL', 'vue3 js', 'Vuex', 'Tailwind css'],
            'short-description': 'A Dutch company that aims to simplify the work with servers, in particular the processes of purchase, regulation and monitoring․',
            description: `<div>
                        <p>
                          A Dutch company that aims to simplify the work with servers, in particular the processes of purchase, regulation and monitoring․
                          The main target groups are startups and developers, more specifically devops engineers.
                        </p>
                      </div>`,
            keyResults: [
                'Increased user experience scores by 55%',
                'Drove best practice implementation in multiple departments.',
            ]
        },
        {
        id: 5,
        image: require('@/assets/projects_logos/beri-dari.svg'),
        logo: require('@/assets/projects_logos/beri-dari.svg'),
        brand: 'Beri-Dari',
        sliderPhotos : [
            'beri-dari1',
            'beri-dari2',
            'beri-dari3',
        ],
        technologies: ['Laravel', 'Vue 3', 'Vuex', 'Tailwind', 'MySQL'],
        'short-description': 'The Ukrainian online gift shop "Beri-Dari" offers the widest range of original and cool gifts at affordable prices',
        description: `<div>
                        <p>
                          The Ukrainian online gift shop "Beri-Dari" offers the widest range of original and cool gifts at affordable prices.
                          Here you will find non-standard gifts even for those who have everything! Thanks to convenient navigation and filters on the site,
                          you can easily find the right souvenir. 
                        </p>
                      </div>`,
        keyResults: null
    },
        {
            id: 6,
            image: require('@/assets/projects_logos/arbostar.svg'),
            logo: require('@/assets/projects_logos/arbostar.svg'),
            brand: 'Arbostar',
            sliderPhotos: [
              'arbostar1',
              'arbostar2',
              'arbostar3',
              'arbostar4',
              'arbostar5',
            ],
            technologies: ['PHP', 'Laravel 8', 'Codeigniter 3', 'Vue2.js', 'MySQL', 'SQL'],
            'short-description': 'ArboStar is a carefully designed business management platform which helps tree service businesses grow revenue, increase profitability, and improve customer satisfaction.',
            description: `<div>
                        <p>
                            ArboStar is a carefully designed business management platform which helps tree service businesses grow revenue, increase profitability,
                            and improve customer satisfaction. We generally worked on the application part, where we developed both the frontend and the backend parts.
                        </p>
                      </div>`,
        },
        {
        id: 7,
        image: require('@/assets/projects_logos/hell-store.svg'),
        logo: require('@/assets/projects_logos/hell-store.svg'),
        brand: 'Hell Store',
        sliderPhotos: [
          'hellstore1',
          'hellstore2',
          'hellstore3',
          'hellstore4',
          'hellstore5',
          'hellstore6',
          'hellstore7',
          'hellstore8',
        ],
        technologies: ['Laravel', 'Vue.js', 'Node.js', 'mySQL', 'Web Sockets'],
        'short-description': 'Hellstore is a gaming platform that provides an opportunity to play in various game modes with CS:GO items. They have been working since 2015, they are trusted by more than 1.700.000 players.',
        description: `<div>
                        <p>
                            Hellstore is a gaming platform that provides an opportunity to play in various game modes with CS:GO items.
                            They have been working since 2015, they are trusted by more than 1.700.000 players.
                            Currently they support 4 game modes: Classic, Coinflip, Upgrade, Wheel. I have been working on Support Coinflip, Daily Free, Bonus, Referral System, WIthdrawal. 
                        </p>
                      </div>`,
        keyResults: null,
    },
        {
            id: 8,
            image: require('@/assets/projects_logos/yobi.svg'),
            logo: require('@/assets/projects_logos/yobi.svg'),
            brand: 'Yobi Da Yobi',
            sliderPhotos: [
                'yobi1',
                'yobi2',
                'yobi3',
                'yobi4',
                'yobi5',
            ],
            technologies: [ 'laravel', 'SQL', 'PHP', 'mySQL'],
            'short-description': `Ebidoebi is an online platform for ordering sushi and rolls and is in the leading positions of the Russian market.
                All this time, the company has been working on its internal processes, management system,to become one of the best sushi restaurants in Russia.`,
            description: `<div>
                        <p>
                          Ebidoebi is an online platform for ordering sushi and rolls and is in the leading positions of the Russian market.
                          All this time, the company has been working on its internal processes, management system,to become one of the best sushi restaurants in Russia.
                          Moreover, they are achieving best results with the help of our great partnership. At the moment, the company has opened 3 branches in Krasnoyarsk, the fourth one in Kemerovo.
                        </p>
                        <p class="mt-2">
                           Digital Threads supports the company by giving them support in analytics of their data and support in backend development. 
                        </p>
                      </div>`,
            keyResults: [
                'Analysis of the data',
                'Backend support',
                'Reliable partnership'
            ]
        },
        {
            id: 9,
            image: require('@/assets/projects_logos/matryoshka.svg'),
            logo: require('@/assets/projects_logos/matryoshka.svg'),
            brand: 'Matryoshka',
            sliderPhotos: [
                'matryoshka1',
                'matryoshka2',
                'matryoshka3',
                'matryoshka4',
                'matryoshka5',
                'matryoshka6',
            ],
            technologies: ['Laravel', 'Redis', 'AVS', 'RabbitMQ', 'Nuxt.js', 'Tailwind', 'Node.js','Postgres/MySQL'],
            'short-description': `Matroshka is a Russian online video sharing and social media platform. Functionality is completely similar to Youtube. App is created for any user located in Russia.`,
            description: `<div>
                        <p>
                          Matroshka is a Russian online video sharing and social media platform.
                          Functionality is completely similar to Youtube. App is created for any user located in Russia.
                          Users can import video, share and listen to any video, as well as comment and use any functionality of it, like in Youtube.
                          There are the following sections on the platform: Navigator, your videos, sign in and life videos.
                        </p>
                      </div>`,
            keyResults: [
                'Together with other partners we are developing an online video sharing and social media platform that can be used by Russian users.',
                'Project is still under development',
            ]
        },
        {
        id: 10,
        image: require('@/assets/projects_logos/workflowz.svg'),
        logo: require('@/assets/projects_logos/workflowz.svg'),
        brand: 'workflowz',
        sliderPhotos: [
            'workflowz1',
            'workflowz2',
            'workflowz3',
            'workflowz4',
            'workflowz5',
            'workflowz6',
            'workflowz7',
            'workflowz8',
            'workflowz9',
            'workflowz10',
        ],
        technologies: ['Laravel', 'Vue js', 'TypeScript', 'mySQL'],
        'short-description': `Workflowz is a Netherlands company, whose aim is to build kitchen furniture with help of different professionals that are taking over different roles:
                          workers (design, build and installing the furniture), clients (the users that are conducting the order), admins and superadmins (the management roles). `,
        description: `<div>
                        <p>
                          Workflowz is a Netherlands company, whose aim is to build kitchen furniture with help of different professionals that are taking over different roles:
                          workers (design, build and installing the furniture), clients (the users that are conducting the order), admins and superadmins (the management roles). 
                        </p>
                        <p class="mt-2">
                            Our team has been in a leading role as full stack developers. We were building the frontend and backend part. Currently, the project is still in process. 
                        </p>
                      </div>`,
    }
    ]

export default projects