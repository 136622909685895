<template>
  <div>
    <div class="max-w-315p text-center">
      <div class="flex gap-1 justify-center">
        <slot name="icon"/>

        <span class="text-navy text-24">
          <slot name="title"/>
        </span>
      </div>

      <div class="mt-6">
        <slot/>
      </div>

      <div class="text-16 text-neutral/400 leading-6 mt-6">
        <slot name="descrioption"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactItem'
}
</script>
