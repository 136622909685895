<template>
  <div class="w-full h-full flex flex-col">

    <page-hero bg="about">
      <template #title>
        We don’t have clients we have <span class="text-primary1"> partners</span>
      </template>
    </page-hero>

<!--    hello section-->
    <div class="w-full h-full flex-col items-start mt-32 mb-16 px-4 md:px-12">
      <div class="w-full h-1/3 flex justify-between flex-col-reverse md:flex-row gap-10 md:gap-20 max-w-content mx-auto ">
        <div class="w-full md:w-1/2 relative">
          <div class="w-full h-500p bg-staff-gallery bg-contain bg-no-repeat"/>
        </div>
        <div class="w-full md:w-1/3 flex flex-col gap-4">
          <div class="text-32 text-primary1 text-left ">Hello</div>
          <div class="text-18p text-black text-left">
            We are Digital Threads!
            <div>We always focus on a partnership model and building long term relationships with our clients. So, our focus is always the best interests of our customers. Company
            founded to deliver technology solutions that positively impact the business. We endeavor to build long-term partnerships with our clients, and that has been the secret to our success.</div>
          </div>
        </div>
      </div>
    </div>



<!--    How we can help your business grow sectio-->
    <section-layout class="max-w-content m-auto">
      <template #title>
        How we can help your business grow
      </template>

      <div class="mx-auto flex flex-wrap justify-center gap-x-8 gap-y-12 pb-16 container-md m-auto">
        <about-help-your-business-card v-for="item in yourBusinessCardData" :key="item.id" class="w-full sm:w-400p">
          <template #title>
            {{ item.title }}
          </template>

          <template #description>
            {{ item.description }}
          </template>
        </about-help-your-business-card>
      </div>
    </section-layout>


<!--    Workflow section-->
    <section-layout class="pt-0 md:pt-0 pr-0" id="workflow">
      <template #title>
        Workflow
      </template>

      <template #subtitle>
        Our web development experts are ready to empower your business with a software of any type and difficulty.
      </template>

      <div class="w-full flex justify-between flex-col-reverse md:flex-row gap-12 md:gap-0">

        <div class="w-full md:w-1/2 flex flex-col items-start gap-8 md:gap-24">
          <div class="w-full flex relative gap-2" v-for="(item, index) in workflowData" :key="item.id">
            <div v-html="item.icon" />
            <div class="w-full flex flex-col gap-2 items-start justify-start">
              <div class="text-dark-cerulean text-18 font-bold">
                {{ item.title }}
              </div>
              <div class="text-black text-16 font-normal text-left opacity-50">
                {{ item.description }}
              </div>
            </div>
            <div v-html="item.numIcon" class="hidden md:block"/>
            <div v-if="index !== workflowData.length-1" class="hidden md:block w-2 h-full bg-step-line bg-no-repeat absolute -right-4 top-40"></div>
          </div>
        </div>

        <div class="w-full md:w-1/2 flex items-center justify-start md:justify-end">
          <img src="@/assets/backgrounds/workflow-section.png" class="block md:hidden">
          <div class="bg-workflow bg-no-repeat bg-cover h-800p w-full hidden md:block md:w-3/4"/>
        </div>

      </div>
    </section-layout>


<!--    peoples section-->
    <section-layout class="pt-0 md:pt-0 pb-150p">
      <template #title>
        People
      </template>

      <template #subtitle>
        Alone we can do so little, together we can do much more!
      </template>

      <div class="w-full flex flex-wrap justify-center gap-8">

        <div v-for="people in peoplesData" :key="people.id" class="h-full flex items-center justify-center flex-col" data-aos="fade-up"  data-aos-duration="2000">
          <div :class="`bg-${people.avatar}`" class="w-300p h-320p bg-no-repeat bg-cover bg-center rounded-70p shadow-4xl"/>
          <div class="w-full text-navy text-center text-24 font-bold">
            {{ people.fullName }}
          </div>
          <div class="text-navy text-18 font-normal">
            {{ people.position }}
          </div>
          <div class="text-dark-cerulean text-20 font-bold cursor-pointer cursor-pointer">
            <a :href="people.linkedin" target="_blank">
              {{ people.contacts }}
            </a>
          </div>
        </div>

        <div class=" h-full flex items-center justify-center flex-col gap-2 cursor-pointer" @click="$router.push('/careers#open-position')">
          <div class="w-300p h-320p flex items-center justify-center flex-col shadow-4xl cursor-pointer rounded-70p border border-transparent hover:border-primary1 px-12 gap-8 ">
            <div class="text-navy text-18 font-normal">See what open positions do we have for YOU </div>
            <div class="bg-go-to w-6 h-3 cursor-pointer bg-no-repeat"> </div>
          </div>
        </div>

      </div>
    </section-layout>

  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<script setup>
import SectionLayout from '@/layouts/SectionLayout'
import AboutHelpYourBusinessCard from '@/components/About/AboutHelpYourBusinessCard'
import { reactive } from 'vue'
import PageHero from '@/components/PageHero'
import staff from '@/store/staff.js'

const yourBusinessCardData = reactive([
  {
    id: 1,
    title: 'Quality vs Quantity',
    description: `That’s the key to our philosophy. We strive to deliver the best service and create the highest quality products by constantly
                  renewing and improving every aspect of our process.`
  },
  {
    id: 2,
    title: 'Strong Relationships',
    description: `We build strong, trusting relationships with our clients and encourage close bonds among our team members.`
  },
  {
    id: 3,
    title: 'Passion and Commitment',
    description: `We are passionate about our work. We are enthusiastic about the work we do and the products we help build. We respect our
                  clients; we put ourselves in their shoes, always listening to everyone’s opinions in order to contribute to new ideas.`
  },
  {
    id: 4,
    title: 'Team over Individuals',
    description: `We are passionate about our work. We are enthusiastic about the work we do and the products we help build. We respect our
                  clients; we put ourselves in their shoes, always listening to everyone’s opinions in order to contribute to new ideas.`
  },
  {
    id: 5,
    title: 'Happiness and Flourishing',
    description: `We are committed to continuously improving ourselves and developing our potential. We cultivate and foster the three L’s: listen,
                  listen and listen. Working hard while having fun in the process is our key to success. We flourish because we love what we do.`
  },
  {
    id: 6,
    title: 'Partnership',
    description: `This is one of our core values that symbolizes win-win results, mutual involvement for fruitful collaboration, and our
                  dedication to our partners’ success as it is ours!`
  }
])

const workflowData = reactive([
  {
    id: 1,
    title: 'First Contact',
    numIcon: `<div class="w-48 h-full bg-01 bg-no-repeat absolute -right-32 top-12"/>`,
    icon: `<div class="w-6 h-4 bg-first-contact bg-no-repeat mt-1"/>`,
    description: `During our first call, we'll tell you more about our expertise, cases, methodology, etc.,
                  and you can give us more information on your project, needs, and background. We'll then
                  develop a plan for our future collaboration, and our business development manager will become your PowerCode engagement assistant.`
  },
  {
    id: 2,
    title: 'Project Discussion and Setup',
    numIcon: `<div class="w-48 h-full bg-02 bg-no-repeat absolute -right-32 top-12"/>`,
    icon: `<div class="w-6 h-4 bg-project-discount-and-setup bg-no-repeat mt-1"/>`,
    description: `Our business development manager engages business analytics, development,
                  and design teams to estimate your project and give professional recommendations in case of necessity. The
                  business development manager will also help you with organizational issues, interviews, design and development team gathering, paperwork, etc.`
  },
  {
    id: 3,
    title: 'Development Phase',
    numIcon: `<div class="w-48 h-full bg-03 bg-no-repeat absolute -right-32 top-12"/>`,
    icon: `<div class="w-6 h-4 bg-development-phase bg-no-repeat mt-1"/>`,
    description: `During the active development stage, your single point of contact is our account manager, who will collaborate with you on all issues till the project is finished and delivered to
                 you. Depending on the collaboration model you choose, you can either communicate with our engineers directly or delegate this to the project manager, who will ensure smooth
                 communication.`
  },
  {
    id: 4,
    title: 'Post-project Communication',
    numIcon: `<div class="w-48 h-full bg-04 bg-no-repeat absolute -right-32 top-12"/>`,
    icon: `<div class="w-6 h-4 bg-post-project-communication bg-no-repeat mt-1"/>`,
    description: `We value all clients' feedback and always ask them to evaluate our performance and suggest improvements if needed.
                  Our business development manager will forever remain open to you in case you want to start other projects or need assistance on the finished project.`
  }
])

const peoplesData = reactive([...staff])

</script>
<style>
.bg-about {
  background-image: url('~@/assets/backgrounds/about.png');
}
</style>