<template>
  <div class="w-full h-full bg-no-repeat bg-cover bg-center h-500p md:h-800p flex items-center justify-center flex-col relative" :class="`bg-${props.bg}`">
    <div class="w-full h-full bg-service-overflow bg-no-repeat absolute top-0 left-0 bg-cover"/>
    <div class="w-full xl:w-1/2 whitespace-normal text-white text-center font-bold text-32 md:text-68 z-10 px-6" data-aos="fade-down">
      <slot name="title"/>
    </div>

    <p class="text-white z-20 text-center mt-5 leading-loose text-18 md:text-24" v-if="$slots.subtitle">
      <slot name="subtitle"/>
    </p>
  </div>
</template>

<script>
export default {
  name: "PageHero"
}
</script>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  bg: {
    type: String,
    required: true
  }
})
</script>
