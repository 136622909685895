<template>
  <div class="flex flex-col gap-0.5 cursor-pointer p-2" @click="mobileMenuHandler">
    <div
      class="w-4 h-0.5 transition duration-150 relative"
      :class="{
        'transform hidden': props.isOpenMobileMenu,
        'bg-white': $router.currentRoute.value.meta.background==='transparent',
        'bg-navy': $router.currentRoute.value.meta.background !=='transparent'
      }"
    ></div>
    <div
      class="w-4 h-0.5 transition duration-150 relative"
      :class="{
        'transform rotate-45 ': props.isOpenMobileMenu,
        'bg-white': $router.currentRoute.value.meta.background==='transparent',
        'bg-navy': $router.currentRoute.value.meta.background !=='transparent'
          }"
    ></div>
    <div
      class="w-4 h-0.5 transition duration-150 relative"
      :class="{
        'transform -rotate-45 bottom-1': props.isOpenMobileMenu,
        'bg-white': $router.currentRoute.value.meta.background==='transparent',
        'bg-navy': $router.currentRoute.value.meta.background !=='transparent'
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BurgerButton'
}
</script>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
const emit = defineEmits()

const props = defineProps(['isOpenMobileMenu'])

const isOpenMenu = ref(false)

// methods
function mobileMenuHandler() {
  isOpenMenu.value = !isOpenMenu.value
  emit('isOpenMenu', isOpenMenu.value)
}
</script>
