const staff = [
  {
    id: 1,
    avatar: `mher`,
    fullName: 'Mher Shahinyan',
    position: 'CEO',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/shahinyanm/',
  },
  {
    id: 2,
    avatar: `nina`,
    fullName: 'Nina Nersesyan',
    position: 'Project/project Manager',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/nina-nersesyan-71ba9913a/'
  },
  {
    id: 3,
    avatar: `maria`,
    fullName: 'Maria Abrahamyan',
    position: 'Front end developer',
    contacts: 'in',
    linkedin: '#'
  },
  {
    id: 4,
    avatar: `grish`,
    fullName: 'Grish Hakobyan',
    position: 'Full Stack Developer',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/grish-hakobyan-682a5b1b4/'
  },
  {
    id: 5,
    avatar: `narek`,
    fullName: 'Narek Khachaturyan',
    position: 'Full Stack Developer',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/narek-khachaturyan-537b20182/'
  },
  {
    id: 6,
    avatar: `vahan`,
    fullName: 'Vahan Niazyan',
    position: 'Full Stack Developer',
    contacts: 'in',
    linkedin: 'https://linkedin.com/in/vahan-niazyan-a693141a2'
  },
  {
    id: 7,
    avatar: `aro`,
    fullName: 'Aro Mkrtchyan',
    position: 'Front end developer',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/aro-mkrtchyan-b510ab1b7',
  },
  {
      id: 8,
      avatar: `davit`,
      fullName: 'Davit Avagyan',
      position: 'Full Stack Developer',
      contacts: 'in',
      linkedin: 'https://www.linkedin.com/in/davit-avagyan-53a766176Faro',
    },
  {
    id: 9,
    avatar: `hayk`,
    fullName: 'Hayk Ohanyan',
    position: 'Front end developer',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/hayk-ohanyan-a4a841246'
  },
  {
    id: 10,
    avatar: `hrant`,
    fullName: 'Hrant Aleksanyan',
    position: 'Front end developer',
    contacts: 'in',
    linkedin: 'https://www.linkedin.com/in/hrantaleksanyan/'
  },


]

export default staff