<template>
  <div class="w-full h-full flex flex-col">
    <!--   --------------------- hero------------------>
    <page-hero bg="contact-us">
      <template #title>
        <span class="text-primary1"> Nice</span> to see you here!
      </template>
      <template #subtitle>
        We can help you build an amazing digital product
      </template>
    </page-hero>
    <!--  ----------------------- contacts------------------------- -->
    <section class="pt-100p">
      <div class="flex justify-around flex-wrap gap-24 sm:gap-5">
        <contact-item v-for="contact in contacts" :key="contact.title" data-aos="fade-down" data-aos-duration="1000">
          <template #icon>
            <img :src="contact.icon">
          </template>

          <template #title>
            {{ contact.title }}
          </template>

          <div v-html="contact.link"></div>

          <template #descrioption>
            {{ contact.description }}
          </template>
        </contact-item>
      </div>
    </section>

    <section-layout>
      <template #title>
        Get in touch!
      </template>

      <form @submit.prevent class="max-w-658p m-auto">
        <div
          class="user-input-wrp w-full relative"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <br/>
          <input
            v-model="form.fullName"
            type="text"
            class="inputText outline-none border border-gray-500 py-2 px-3 w-full rounded-tl-2xl rounded-r-2xl"
            required
          />
          <span class="floating-label text-16 text-navy/016">Full Name</span>
        </div>

        <div
          class="user-input-wrp w-full relative"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <br/>
          <input
            v-model="form.email"
            type="text"
            class="inputText outline-none border border-gray-500 py-2 px-3 w-full rounded-tl-2xl rounded-r-2xl"
            :class="{'emailError': emailValidate}"
            required
          />
          <span
            class="floating-label text-16 text-navy/016"
            :class="{'emailError': emailValidate}"
          >
            Contact Email
          </span>
        </div>

        <div
          class="user-input-wrp w-full relative"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <br/>
          <input
            v-model="form.subject"
            type="text"
            class="inputText outline-none border border-gray-500 py-2 px-3 w-full rounded-tl-2xl rounded-r-2xl"
            required
          />
          <span class="floating-label text-16 text-navy/016">Subject</span>
        </div>

        <div
          class="user-input-wrp w-full relative"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <br/>
          <textarea
            v-model="form.message"
            type="text"
            class="inputText outline-none border border-gray-500 rounded-tl-2xl rounded-r-2xl w-full py-2 px-3 resize-none h-150p"
            required
          />
          <span class="floating-label text-16 text-navy/016">Your Message</span>
        </div>


        <div
          class="h-180p mt-1 flex gap-2"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="500"
          @dragover.prevent
          @drop.prevent="dragFile"
        >
          <label class="h-full w-full border border-dashed border-neutral/400 flex flex-col justify-center items-center cursor-pointer rounded-2xl">
            <input
              type="file"
              class="hidden"
              @change="uploadFile"
              :accept="fileAcceptedTypes.join(',')"
              multiple
            />
            <img src="@/assets/icons/file.svg">
            <span class="mt-2 text-16 text-blue_500">
              Select your file to upload
            </span>
            <span class="mt-2 text-14 text-neutral/500">
              or drag and drop it here
            </span>
          </label>
        </div>
        <!--        uploaded files-->
        <template v-if="fileValue.length">
          <div v-for="file in fileValue" :key="file.name" class="bg-neutral/400/01 mt-6 px-6 py-1.5 rounded-2xl flex gap-5">
            <img src="@/assets/icons/file_icon.svg">
            <div class="flex flex-col w-full">
              <span class="text-blue_500 text-14 font-medium">{{ file.name }}</span>

              <div class="w-full overflow-hidden rounded-2xl h-1 bg-neutral/400/02 mt-2 mb-1">
                <div class="bg-primary1 bg-yellow-100 h-full w-full"/>
              </div>

              <span class="text-12 text-neutral/400">{{ ((file.size/1024)/1024).toFixed(2) }}MB</span>
            </div>
            <div class="flex justify-center items-center">
              <img src="@/assets/icons/remove.svg" class="cursor-pointer" @click="removeUploadedFile(file.name)">
            </div>
          </div>
        </template>

        <div v-if="isSuccess" class="flex flex-col w-full items-center px-6 py-5">
          <div class="mb-6">
            <img src="@/assets/icons/success.svg" class="cursor-pointer" @click="removeUploadedFile(file.name)">
          </div>
          <p class="font-extrabold text-18">Your submission has been sent!</p>
        </div>

        <div class="flex items-center justify-center mt-14">
          <custom-button
            @buttonClick="submitHandler"
            :isDisabled="validator"
          >
            Send
          </custom-button>
        </div>

      </form>
    </section-layout>

    <div id="map" class="bg-map bg-no-repeat bg-center bg-cover w-full h-464p"/>
  </div>
</template>

<script>
export default {
  name:  'ContactUs',
}
</script>

<script setup>
import ContactItem from '@/components/Contact_us/ContactItem'
import { ref, reactive, computed } from 'vue'
import SectionLayout from '@/layouts/SectionLayout'
import PageHero from '@/components/PageHero'
import CustomButton from "@/components/UI_components/CustomButton";
const fileValue = reactive([])
const fileAcceptedTypes = ['.jpg', '.jpeg', '.pdf', '.doc', '.docx']
const uploadFile = (e) => {
  const files = Array.from(e.target.files);
  files.forEach(f =>  _checkFileTypeAndSizeAndAdd(f))
}
const dragFile = (e) => {
  const files = Array.from(e.dataTransfer.files)
  files.forEach(f => _checkFileTypeAndSizeAndAdd(f))
}
const removeUploadedFile = (fileName) => {
  const idx = fileValue.findIndex(item => item.name === fileName)
  fileValue.splice(idx, 1)
}
const _checkFileTypeAndSizeAndAdd = file => {
  const ifTypeAccepted = fileAcceptedTypes.find(type => file.type.includes(type.slice(1)))
  if (!ifTypeAccepted) return
  if (file.size !== 0 && file.size/1024/1024 <= 10) {
    fileValue.push(file)
  }
}
const contacts = reactive([
  {
    icon: require('@/assets/icons/email_primary.svg'),
    title: 'Email Us',
    link: '<a href="mailto:digitthreads@gmail.com" class="underline text-18 text-navy">hr@dthreads.am</a>',
    description: 'Our support team will get back to in 48-h during standard business hours.'
  },
  {
    icon: require('@/assets/icons/location_primary.svg'),
    title: 'Find Us',
    link: '<a href="#map" class="underline text-18 text-navy">Abelyan Street, 6/4, Yerevan, Armenia </a>',
    description: 'Drop by for a visit, we are here from Mon-Fri, 09:00 to 18:00'
  },
  {
    icon: require('@/assets/icons/phone_primary.svg'),
    title: 'Call Us',
    link: `<a href="tel:${process.env.VUE_APP_SITE_CONTACT_US_PHONE_NUMBER}" class="underline text-18 text-navy">${process.env.VUE_APP_SITE_CONTACT_US_PHONE_NUMBER}</a>`,
    description: 'Our support team will get back to in 48-h during standard business hours.'
  }
])
const initialForm = {
  fullName: '',
  email: '',
  subject: '',
  message: '',
  filesId: null
}
const isSuccess = ref(false)
const form = reactive({ ...initialForm })
const sending = ref(false)
const emailValidate = computed(() => form.email !== '' && !/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(form.email))
const validator = computed(() => {
  return (form.fullName === '' || form.email === '' || form.subject === '' || form.message === '' || emailValidate.value || sending.value)
})
const submitHandler = async() => {
  sending.value = true
  if (fileValue.length) {
    try {
      const filesId = await addFiles(fileValue)
      form.hasFile = true
      const contactId = await addContact(form, filesId)
    } catch (e) {
      console.error(e)
    }
  } else {
    const contactId = await addContact(form)
  }
  sending.value = false
  Object.assign(form, initialForm)
  fileValue.splice(0)
}
const addContact = async (data, filesId) => {
  try {
    const baseURL = `${process.env.VUE_APP_BACK_END_API_BASE_URL}/contact/send`
    data.filesId = filesId
    const response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    const res = await response.json()
    if (res.id) isSuccess.value = true

    return res.id
  } catch (e) {
    console.error(e)
  }
}
const addFiles = async files => {
  const ids = []
  const response = files.map(async file => await addFile(file))
  for await (const id of response) {
    if (id) {
      ids.push(id)
    }
  }
  return ids
}
const addFile = async file => {
  try {
    const baseURL = `${process.env.VUE_APP_BACK_END_API_BASE_URL}/file/upload`
    const data = new FormData()
    data.append('file', file)
    const response = await fetch(baseURL, {
      method: 'POST',
      body: data
    })
    const id = await response.json()
    return id.id
  } catch (e) {
    console.error(e)
  }
}
</script>

<style scoped>
.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 33px;
  left: 10px;
  transition: 0.2s ease all;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp textarea:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label,
.user-input-wrp textarea:not(:focus):valid ~ .floating-label{
  top: 4px;
  font-size: 13px;
  opacity: 1;
}
.bg-contact-us {
  background-image: url('~@/assets/backgrounds/contact-us-background.png');
}
.emailError {
  border-color: red;
  color: red;
}
</style>