<template>
  <div class="w-full h-full flex flex-col">

    <page-hero bg="career">
      <template #title>
        Join Our best team of<span class="text-primary1"> Professionals</span>
      </template>

      <template #subtitle>
        We work hard, and push ourselves to become better, <br>
        but we also try to have a lot of fun along the way.
      </template>
    </page-hero>

    <div class="w-full h-full bg-white flex items-center justify-center flex-col relative mt-4 mb-16" id="open-position">
      <section-layout>
        <template #title>
          Open positions
        </template>

        <div class="mx-auto flex flex-wrap justify-center items-center pb-16 container-md">
          <div class="flex gap-x-8 gap-y-12 flex-wrap max-w-content justify-center">
            <router-link
              to="contact-us"
              v-for="(position, index) in openPositions"
              :key="index" :class="{'hover:border-primary1':index %2 ===0,'hover:border-navy':index %2 !==0}"
              class="position-card overflow-hidden max-w-full sm:max-w-315p bg-white rounded-45p shadow-4xl dark:bg-gray-800 dark:border-gray-700 border border-transparent pt-10 pb-4 cursor-pointer relative"
              data-aos="flip-left"
              data-aos-duration="2000"
              data-aos-easing="ease-out-cubic"
            >
              <div :class="{'bg-position-yellow':index %2 ===0,'bg-position-navy':index %2 !==0}" class=" ml-1 w-full h-50p bg-no-repeat flex items-center justify-start px-6">
                <div :class="{'text-black ':index %2 ===0,'text-white':index %2 !==0}" class="text-20 font-bold">{{ position.position }}</div>
              </div>
              <div class="flex flex-col items-start gap-8 px-6 pt-2">
                <div class=" w-full text-left text-16 opacity-50 font-normal text-black">{{ position.position_type }}</div>
                <div class="text-16 text-gray-500 font-normal text-left">
                  {{ position.short_description }}
                </div>
              </div>
              <div class="w-full flex items-center justify-start gap-6 px-6 py-4">
                <div class="text-black"> {{ position.type }}</div>
                <div class="text-black"> {{ position.location }}</div>
              </div>
              <div
                :class="{'bg-primary1':index %2 === 0,'bg-navy':index %2 !== 0,}"
                class="card-icon px-7 py-9 text-white font-bold text-16 rounded-full w-125p absolute -bottom-6 -right-6 opacity-100 md:opacity-0 visible md:invisible"
              >
                APPLY <br>
                HERE!
              </div>
            </router-link>

            <div
              class="max-w-full sm:max-w-315p bg-white rounded-45p shadow-4xl dark:bg-gray-800 dark:border-gray-700 border border-transparent pt-10 pb-4 hover:border-navy"
              data-aos="flip-left"
              data-aos-duration="2000"
              data-aos-easing="ease-out-cubic"
            >
              <div class="ml-1 w-full h-50p bg-no-repeat flex items-center justify-start px-6">
                <div class="text-24 font-bold text-navy text-left">
                  Don’t see the job you were looking for?
                </div>
              </div>
              <div class="flex flex-col items-start gap-8 px-6 pt-2">
                <div class="mt-10 text-18 text-navy font-normal text-left">
                    Send your CV at <a href="mailto:hr@dthreads.am" class="underline">hr@dthreads.am</a> We will let you know if something comes up!
                </div>
              </div>

              <img
                src="@/assets/woman.png"
                class="w-215p relative left-1/2 -bottom-5"
              >
            </div>
          </div>
        </div>
      </section-layout>

      <section-layout class="pt-0 md:pt-0">
        <template #title>
          Our Values
        </template>

        <div class="mx-auto flex flex-wrap gap-20 pb-16 container-md mt-20 justify-center">
          <our-values-item
            v-for="ourValue in ourValues" :key="ourValue.title"
            data-aos="zoom-in-up"
            data-aos-duration="2000"
          >
            <template #icon>
              <img :src="ourValue.icon">
            </template>

            <template #title>
              {{ ourValue.title }}
            </template>

            <template #description>
              {{ ourValue.description }}
            </template>
          </our-values-item>
        </div>
      </section-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Careers',
}
</script>

<script setup>
import { reactive } from 'vue'
import OurValuesItem from '@/components/Careers/OurValuesItem'
import SectionLayout from '@/layouts/SectionLayout'
import PageHero from '@/components/PageHero'

const openPositions = reactive([
  {
    position: 'Full stack developer/mid',
    position_type: 'Software Engineer',
    type: 'Full time',
    location: 'Yerevan',
    description: 'We are looking for an experienced Front-end Developer who will be responsible for implementing visual and interactive elements that users engage with through their web browser when using a web application.',
    short_description: 'We are looking for an experienced Front-end Developer who will be responsible for implementing visual and interactive elements that users engage with through their web browser when using a web application.',
  },
])
const ourValues = reactive([
  {
    icon: require('@/assets/our_values_icons/Vector1.svg'),
    title: 'EFFECTIVENESS',
    description: 'We strive towards showing measurable results in the fields that apply to us, to adapt to ever-changing conditions and to be an example of success',
  },
  {
    icon: require('@/assets/our_values_icons/Vector2.svg'),
    title: 'Responsibility',
    description: 'For ourselves, others, the company, its values and our surrounding environment',
  },
  {
    icon: require('@/assets/our_values_icons/Vector3.svg'),
    title: 'Progress',
    description: 'Constant, never-ending technical, intellectual, social, emotional and financial evolution, both personal and professional.',
  },
  {
    icon: require('@/assets/our_values_icons/Vector4.svg'),
    title: 'Team Workspace',
    description: 'Our office design is meant to empower creativity, boost teamwork and keep us united',
  }
])
</script>

<style scoped>
.position-card:hover .card-icon{
  transition: visibility 0s, opacity .3s linear;
  visibility: visible;
  opacity: 1;
}
.bg-career {
  background-image: url('~@/assets/backgrounds/careers-background.png');
}
</style>