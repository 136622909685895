<template>
  <div
    v-if="$router.currentRoute.value.meta.background==='transparent'"
    id="nav"
    class="w-full flex items-center justify-between w-full py-6 md:py- px-4 fixed sm:px-16 z-30 shadow-4xl bg-navy/07"
  >
    <div class="flex items-center justify-start w-100p h-9 cursor-pointer">
      <div class="bg-logo w-full h-full bg-no-repeat bg-contain" @click="goToHome"></div>
    </div>

    <div
      v-if="windowWidth > 1024"
      class="w-10/12 flex justify-end items-center gap-14 h-full"
    >
      <router-link to="/about" class="text-white font-normal hover:font-bold text-18 relative w-24">About us</router-link>
      <router-link to="/services" class="text-white font-normal hover:font-bold text-18 relative w-24">Services</router-link>
      <router-link to="/projects" class="text-white font-normal hover:font-bold text-18 relative w-24">Projects</router-link>
      <router-link to="/careers" class="text-white font-normal hover:font-bold text-18 relative w-24">Careers</router-link>
      <button
        @click="$router.push('contact-us')"
        class="hover:bg-primary1 text-black font-normal text-18 rounded-full cursor-pointer bg-white border hover:border-transparent border-primary1 py-3 px-8"
      >
        Contact Us
      </button>
    </div>
    <div v-else>
      <burger-button @isOpenMenu="isOpenMenu" :isOpenMobileMenu="isOpenMobileMenu"/>
    </div>

    <mobile-menu
      v-if="isOpenMobileMenu && windowWidth <= 1024"
      class="absolute top-84p left-0 right-0 shadow-inner shadow-4xl z-50"
      @clickLink="isOpenMenu(false)"
    />
  </div>

  <div class="w-full flex items-center justify-between w-full py-6 md:py- px-4 sm:px-16 fixed z-30 bg-white" id="nav" v-else>
    <div class="flex items-center justify-start w-100p h-9 cursor-pointer">
      <div class="bg-logo-blue w-full h-full bg-no-repeat bg-contain" @click="goToHome"></div>
    </div>
    <div
      v-if="windowWidth > 1024"
      class=" w-10/12 flex justify-end items-center gap-14 h-full">

      <router-link to="/about" class="text-navy font-normal hover:font-bold text-18 relative w-24">About us</router-link>
      <router-link to="/services" class="text-navy font-normal hover:font-bold text-18 relative w-24">Services</router-link>
      <router-link to="/projects" class="text-navy font-normal hover:font-bold text-18 relative w-24">Projects</router-link>
      <router-link to="/careers" class="text-navy font-normal hover:font-bold text-18 relative w-24">Careers</router-link>
      <button
        @click="$router.push('contact-us')"
        :class="{'bg-primary1': $router.currentRoute['_value'].fullPath === '/contact-us'}"
        class="hover:bg-primary1 text-black font-normal text-18 rounded-full cursor-pointer bg-white border hover:border-transparent border-primary1 py-3 px-8"
      >
        Contact Us
      </button>
    </div>

    <div v-else>
      <burger-button @isOpenMenu="isOpenMenu" :isOpenMobileMenu="isOpenMobileMenu"/>
    </div>
    <mobile-menu
      v-if="isOpenMobileMenu && windowWidth <= 1024"
      class="absolute top-84p left-0 right-0 shadow-inner shadow-4xl z-50"
      @clickLink="isOpenMenu(false)"
    />
  </div>


</template>

<script>
export default {
  name: 'Navbar',
}
</script>

<script setup>
import { onMounted, ref, onBeforeUnmount, watch  } from 'vue'
import { useRouter } from 'vue-router'
import BurgerButton from "@/components/BurgerButton";
import MobileMenu from "@/components/MobileMenu";

let isOpenMobileMenu = ref(false)

let windowWidth = ref(null)

function isOpenMenu(val) {
  isOpenMobileMenu.value = val
}

onMounted(() => {
  windowWidth.value = document.body.clientWidth
  window.addEventListener('resize', () => {
    windowWidth.value = document.body.clientWidth
  });
})

onBeforeUnmount(() => {
  window.removeEventListener('resize',(event) => {
    windowWidth.value = document.body.clientWidth
  })
})

watch(isOpenMobileMenu, (value) => {
  if (value) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }
})

const router = useRouter()
const goToHome = async ()=>{
  await router.push('/')
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing: border-box;
}

#nav a:hover {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  font-weight: bold;
}

#nav a.router-link-exact-active:after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: #FFCE1F;
  border-radius: 50%;
  top: 100%;
  right: 50%;
}
</style>
