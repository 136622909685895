<template>
  <div
    class="rounded-full max-w-content w-11/12 md:w-4/6 py-10 px:12 md:py-12 md:px-16 flex flex-col md:flex-row justify-around md:justify-around gap-3 items-center absolute top-0 transform -translate-y-1/2 shadow-4xl bg-white px-12 z-20"
  >
    <div class="text-20 sm:text-32 items-center text-navy font-bold text-center">
      <slot name="title"/>
    </div>
    <custom-button @buttonClick="buttonClick">
      <slot name="button-label"/>
    </custom-button>
  </div>
</template>

<script>
export default {
  name: 'footerHead',
}
</script>

<script setup>
  import CustomButton from '@/components/UI_components/CustomButton'
  import { defineEmits } from 'vue'

  const emit = defineEmits()

  const buttonClick = () => {
    emit('buttonClick')
  }

</script>
