<template>
  <div class="flex flex-col items-center bg-white min-h-screen pt-8 z-50 bg-white">
    <router-link to="/" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">Home</router-link>
    <router-link to="/about" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">About us</router-link>
    <router-link to="/services" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">Services</router-link>
    <router-link to="/projects" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">Projects</router-link>
    <router-link to="/careers" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">Careers</router-link>
    <router-link to="/contact-us" class="text-white font-normal text-18 relative text-navy p-2" @click="clickLink">Contact us</router-link>
  </div>
</template>

<script>
export default {
  name: 'MobileMenu'
}
</script>

<script setup>
import { defineEmits } from 'vue'
const emit = defineEmits()

function clickLink() {
  emit('clickLink')
}
</script>

<style scoped>
a:hover {
  font-weight: bold;
}

a.router-link-exact-active {
  font-weight: bold;
}
</style>
