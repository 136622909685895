<template>
  <div class="w-1/4 bg-white rounded-45p border border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700 px-6 py-10" data-aos="flip-left"
       data-aos-duration="2000"
       data-aos-easing="ease-out-cubic">
    <div class="flex flex-col items-center gap-3">
      <div class="bg-yellow-success w-64p h-62p bg-no-repeat "></div>
      <div class=" w-full text-center text-20 text-black font-bold">
        <slot name="title"/>
      </div>
      <div class="text-16 text-gray-500 font-normal text-left">
        <slot name="description"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutHelpYourBusinessCard'
}
</script>
