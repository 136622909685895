<template>

  <div class="w-full h-full flex flex-col">
    <Particles
        id="tsparticles"
        :options="{
                    background: {
                        color: {
                            value: '#041729'
                        }
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: 'push'
                            },
                            onHover: {
                                enable: true,
                                mode: 'repulse'
                            },
                            resize: true
                        },
                        modes: {
                            bubble: {
                                distance: 250,
                                duration: 2,
                                opacity: 0.8,
                                size: 5
                            },
                            push: {
                                quantity: 4
                            },
                            repulse: {
                                distance: 100,
                                duration: 0.4
                            }
                        }
                    },
                    particles: {
                        color: {
                            value: '#ffffff'
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1
                        },
                        collisions: {
                            enable: true
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outModes: {
                                default: 'out'
                            },
                            random: true,
                            speed: 3,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800
                            },
                            value: 80
                        },
                        opacity: {
                            value: 0.5
                        },
                        shape: {
                            type: 'circle'
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        }
                    },
                    detectRetina: true
                }"
        :particlesInit="particlesInit"
        :particlesLoaded="particlesLoaded"/>
    <div class="w-full h-full px-6 h-500p lg:h-800p flex items-center justify-center flex-col relative">
      <div class="text-white font-normal text-18 lg:text-24 z-20" data-aos="fade-down">Accelerate your business with</div>
      <div class="text-white font-bold text-32 lg:text-68 uppercase z-20 text-center" data-aos="fade-down">
        <span class="text-primary1"> result-driven</span> software
      </div>
      <div class="text-white font-bold text-32 lg:text-68 uppercase z-20" data-aos="fade-down"> development</div>

      <div class="
          absolute
          m-auto
          bottom-0
          py-7
          sm:py-11
          px-100p
          rounded-100p
          max-w-content
          w-11/12 sm:w-4/6
          flex
          flex-col lg:flex-row
          justify-around lg:justify-between
          items-center
          absolute
          bottom-0
          transform translate-y-50c
          shadow-4xl
          bg-white
          z-20
          "
      >
        <div class="text-20 text-neutral/500 text-center lg:text-left font-bold">
          Are you looking for help with web, mobile or software application development
        </div>
        <custom-button class="lg:mt-0 mt-10" @buttonClick="$router.push('contact-us')">
          Let’s discuss!
        </custom-button>
      </div>
    </div>


<!--    How we can help your business grow section-->
    <section-layout class="pt-215p xl:pt-150p">
      <template #title>
        How we can help your business grow
      </template>

      <div
        class="
            mx-auto
            flex
            flex-wrap
            justify-center
            gap-4 sm:gap-x-10 lg:gap-x-20  sm:gap-y-6 lg:gap-y-12
            pb-16
            container-md
            px-6"
      >
        <help-your-business-cart v-for="item in helpBusinessData" :key="item.id">
          <template #icon>
            <div v-html="item.icon"/>
          </template>

          <template #title>
            {{ item.title }}
          </template>

          <template #description>
            {{ item.description }}
          </template>
        </help-your-business-cart>

        <div class="w-full flex items-center justify-center mt-80p">
          <custom-button type="primary" :arrow="true" @buttonClick="$router.push('services')">
            Observe all services
          </custom-button>
        </div>

      </div>

    </section-layout>

<!--    Our Development process section-->
    <div class="w-full h-800p bg-no-repeat bg-cover flex items-center justify-center flex-col relative gap-12 ">
      <div class="w-full flex items-center justify-center flex flex-col max-w-content mx-auto gap-12">
        <div class="text-24 md:text-32 text-white text-center font-bold">Our Development process</div>
        <div class="w-full hidden md:flex justify-between items-end px-32 ">
          <div class="bg-first-step bg-no-repeat w-32 h-32 transform -translate-x-1/2"></div>
          <div class="bg-second-step bg-no-repeat w-32 h-32"/>
          <div class="bg-third-step bg-no-repeat w-32 h-32"/>
          <div class="bg-fourth-step bg-no-repeat w-44 h-24 transform translate-x-1/2"></div>
        </div>

        <div class="w-full px-32 h-12 hidden md:flex items-center justify-start">
          <div class="bg-process-line bg-no-repeat w-full h-full bg-contain"/>
        </div>
        <div class="process-item-wrapper flex flex-col md:flex-row justify-between md:items-center px-6 md:px-32">

          <div
            class="process-item relative w-full md:w-2/12 text-white text-24 font-bold whitespace-normal text-left md:text-left"
            data-aos="zoom-in-right"
            data-aos-duration="6000"
          >
            First Contact
          </div>
          <div
            class="process-item relative w-full md:w-2/12 text-white text-24 font-bold whitespace-normal text-left md:text-center"
            data-aos="zoom-in-right"
            data-aos-duration="3000"
          >
            Project Discussion and Setup
          </div>
          <div
            class="process-item relative w-full md:w-2/12 text-white text-24 font-bold whitespace-normal text-left md:text-center"
            data-aos="zoom-in-right"
            data-aos-duration="3000"
          >
            Development Phase
          </div>
          <div
            class="process-item relative w-full md:w-2/12 text-white text-24 font-bold whitespace-normal text-left md:text-right"
            data-aos="zoom-in-right"
            data-aos-duration="2500"
          >
            Post-Project Communication
          </div>

        </div>
        <div class="w-full flex items-center justify-center mt-24">
          <custom-button :arrow="true" @buttonClick="$router.push('/about#workflow')">
            More Details
          </custom-button>
        </div>
      </div>
    </div>


<!--    Why Choose Us section-->
    <section-layout>
      <template #title>
        Why Choose Us
      </template>

      <div class="w-full flex justify-center flex-col lg:flex-row gap-8 lg:gap-10">
        <div class="w-full lg:w-1/2 md:h-500p p-33c md:p-0 bg-contain bg-why-choose-us bg-no-repeat" data-aos="fade-down-right"/>
        <div class="lg:w-2/6 flex flex-col lg:items-start justify-center gap-4">

          <div class="flex justify-start gap-2 items-center" v-for="(item, index) in WhyChooseUsData" :key="`${item}${index}`">
            <div class="w-6 h-6 bg-point bg-no-repeat"></div>
            <div class="text-black text-24 font-normal">
              {{ item }}
            </div>
          </div>

          <div class="flex items-center justify-center md:justify-start mt-50p md:mt-6">
            <custom-button :arrow="true" @buttonClick="$router.push('about')">
              About us
            </custom-button>
          </div>

        </div>
      </div>

    </section-layout>

<!--    The Team section-->

    <section-layout class="md:pt-0 pb-150p">
      <template #title>
        The Team
      </template>

      <template #subtitle>
        Alone we can do so little, together we can do much more!
      </template>

      <div class="w-full h-full relative flex items-center justify-center mt-8">
        <div class="w-full relative flex flex-col items-center ">
          <div class="w-1/2 flex items-center justify-center relative my-20 max-w-content w-300p sm:hidden" >
            <div class="team-image-content max-w-content"/>
            <img src="@/assets/backgrounds/team.png" class="w-full z-10">
          </div>

          <div class="w-full sm:w-5/6 flex flex-col sm:flex-row items-center justify-between">
            <div class="sm:w-48 sm:h-48 flex flex-col items-center justify-center">
              <div class="text-dark-cerulean text-88 font-bold ">30+</div>
              <div class="text-black opacity-50 text-18 text-center font-normal">Successful Projects Delivered</div>
            </div>
            <div class="sm:w-48 sm:h-48 flex flex-col items-center justify-center">
              <div class="text-dark-cerulean text-88 font-bold">15+</div>
              <div class="text-black opacity-50 text-18 text-center font-normal">Skilled Developers</div>
            </div>
          </div>

          <div class="w-1/2 items-center justify-center relative my-20 max-w-content w-300p hidden sm:flex">
              <div class="team-image-content max-w-content"/>
              <img src="@/assets/backgrounds/team.png" class="w-full z-10">
          </div>

          <div class="w-full sm:w-5/6 flex flex-col sm:flex-row items-center justify-between">
            <div class="sm:w-48 sm:h-48 flex flex-col items-center justify-center">
              <div class="text-dark-cerulean text-88 font-bold">3000+</div>
              <div class="text-black opacity-50 text-18 text-center font-normal">Cups of Coffee</div>
            </div>
            <div class="sm:w-48 sm:h-48 flex flex-col items-center justify-center">
              <div class="text-dark-cerulean  text-88 font-bold ">20+</div>
              <div class="text-black opacity-50 text-18 text-center font-normal">Happy Clients</div>
            </div>
          </div>

        </div>
      </div>
    </section-layout>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>
<script setup>

import { loadFull } from 'tsparticles'
import { onBeforeMount, reactive } from 'vue';
import HelpYourBusinessCart from '@/components/Home/HelpYourBusinessCart'
import CustomButton from '@/components/UI_components/CustomButton'
import SectionLayout from '@/layouts/SectionLayout'

const helpBusinessData = reactive([
  {
    id: 1,
    icon: `<div class="bg-web-application-development w-64p h-62p bg-no-repeat"></div>`,
    title: `Web Application Development`,
    description: `Regardless of how complex or even small your needs are, our dedicated in-house team of web developers
                  and project managers will ensure that the job gets done with the highest level of professionalism and most cost affordably.`
  },
  {
    id: 2,
    icon: `<div class="bg-testing-qa w-64p h-62p bg-no-repeat"/>`,
    title: `Testing and QA`,
    description: `We reimagine QA and end-to-end testing strategies to help you achieve success in digital.`
  },
  {
    id: 3,
    icon: `<div class="bg-mobile w-64p h-62p bg-no-repeat"/>`,
    title: `Mobile Application Development`,
    description: `We help businesses appear in the Google Play Store and Apple Store with their custom business app.
                  We transform your idea into a powerful online service that conveys trust and respect to the users.`
  },
  {
    id: 4,
    icon: `<div class="bg-custom-software w-64p h-62p bg-no-repeat"/>`,
    title: `Custom Software Development`,
    description: `If you have the next software project in mind - we have the cross-functional
                  expertise that’ll help you with tailored custom software development solutions while cutting project costs`
  },
  {
    id: 5,
    icon: `<div class="bg-outsource w-64p h-62p bg-no-repeat"/>`,
    title: `Outsourced Product Development`,
    description: `We productize your ideas in a way that helps you stay a cut above the ordinary. We bring together
                  our top talents to design your product, roll it out in the market and enhance it iteratively.`
  }
])

const WhyChooseUsData = reactive([
      'Quality over Quantity',
      'Strong Relationships',
      'Passion and Commitment',
      'Team over Individuals',
      'Happiness and Flourishing',
      'Partnership'
    ])

const particlesInit = async (engine) => {
  await loadFull(engine)
}
const particlesLoaded = async (container) => {
  console.log('Particles container loaded', container)
}

onBeforeMount( async ()=>{
  const baseURL = `${process.env.VUE_APP_BACK_END_API_BASE_URL}/frontend/service`

  const response = await fetch(baseURL, {
    method: 'Get',
    headers: {
      'Content-Type': 'application/json'
    },
  })
  console.log('Response::::', response)
})

</script>

<style scoped>
::v-deep(canvas) {
  position: relative !important;
}
.team-image-content:after,
.team-image-content:before {
  content: '';
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
}
.team-image-content:after {
  width: 148px;
  height: 172px;
  left: -53px;
  top: -41px;
  background-image: url("../assets/icons/circle.svg");
}
.team-image-content:before {
  width: 139px;
  height: 128px;
  bottom: -48px;
  right: -44px;
  background-image: url("../assets/icons/triangle.svg");
}

@media(max-width: 768px) {
  .process-item-wrapper {
    border-left: 6px solid #a9a9a9;
    margin-left: 110px;
  }
  .process-item-wrapper > .process-item:not(:last-child) {
    padding-bottom: 72px;
  }
  .process-item:before {
    color: #a9a9a9;
    font-size: 64px;
    position: absolute;
    top: -36px;
    left: -120px;
    width: 100px;
    height: 100px;
  }
  .process-item:nth-child(1):before {
    content: '01';
  }
  .process-item:nth-child(2):before {
    content: '02';
  }
  .process-item:nth-child(3):before {
    content: '03';
  }
  .process-item:nth-child(4):before {
    content: '04';
  }
  .process-item:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #a9a9a9;
    top: -2px;
    border-radius: 50%;
    left: -39px;
  }
  .process-item:last-child:after {
    top: 12px;
  }
}
@media(max-width: 640px) {
  .team-image-content:after,
  .team-image-content:before {
    width: 69px;
    height: 81px;
  }
  .team-image-content:after {
    left: -20px;
    top: -20px;
  }
  .team-image-content:before {
    bottom: -40px;
    right: -20px;
  }
}

</style>
