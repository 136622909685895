<template>
  <div class="card w-full min-w-350p md:min-w-250p sm:w-1/3 lg:w-1/4 bg-white rounded-45p border border-gray-200 shadow-xl dark:bg-gray-800 dark:border-gray-700 px-6 py-6 cursor-pointer hover:border-primary1 relative"
       data-aos="flip-left"
       data-aos-duration="2000"
       data-aos-easing="ease-out-cubic"
  >
    <div class="flex flex-col items-start pb-4 gap-3">
      <div class="flex justify-between w-full">
        <slot name="icon"/>
        <div class="arrow bg-hover-arrow bg-no-repeat bg-contain w-12 h-12 opacity-100 md:opacity-0 visible md:invisible"/>
      </div>

      <div class="w-full text-left text-20 text-navy font-bold">
        <slot name="title"/>
      </div>
      <div class="text-16 text-gray-500 font-normal text-left">
        <slot name="description"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpYourBusinessCart'
}
</script>

<style scoped>
  .card:hover .arrow {
    transition: visibility 0s, opacity .3s linear;
    visibility: visible;
    opacity: 1;
  }
</style>
