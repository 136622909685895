<template>
  <div class="w-full h-full flex flex-col">

    <page-hero bg="service">
      <template #title>
        Solutions we offer
      </template>

      <template #subtitle>
        <span class="text-primary1">Entrust</span> your company IT project to the <span class="text-primary1">managed development team</span>
      </template>
    </page-hero>

    <div
      class="hide-scrollbar navigation px-12 py-6 shadow-4xl hidden sm:flex gap-11 whitespace-nowrap justify-between overflow-scroll"
      @mouseover="switchTooltip($event, 'show')"
      @mouseleave="switchTooltip($event, 'hide')"
    >
      <div v-for="(service, index) in Object.keys(services)" :key="index" class="text-neutral/500 text-20 font-bold">
        <a :href="`#${service}`">
          <span class="text-shadow hover:underline">
            {{ service.slice(0, -8) }}
          </span>
        </a>
      </div>
      <div ref="tooltip" class="tooltip bg-black text-white hidden fixed rounded-lg px-8 py-1 bg-white border border-black text-navy font-bold opacity-70">
        shift + scroll
      </div>
    </div>
    <div class="sm:hidden py-3 text-18 font-bold text-neutral/400 cursor-pointer">
      <div class="flex items-center gap-2 justify-center pb-1.5 border-b" @click="dropdownHandler">
        <span>All Services</span>
        <span class="bg-chevron bg-no-repeat bg-contain w-3 h-2" :class="{ 'transform rotate-180' : isOpenDropdown }"/>
      </div>
      <div class="custom-select-wrapper text-18 font-bold text-neutral/400 cursor-pointer transition-all duration-350 ease-in-out h-0 overflow-hidden"
           :class="[isOpenDropdown? 'h-215p' : 'h-0']"
      >
        <a :href="`#${service}`" v-for="(service, index) in Object.keys(services)" :key="index" class="text-center py-1.5 border-b hover:underline block">
          {{ service.slice(0, -8) }}
        </a>
      </div>
    </div>

    <div class="w-full h-full flex-col items-start gap-12">
      <div v-for="section in Object.keys(services)" :key="section" :id="section" class="w-full h-full flex flex-col relative gap-12 sm:gap-24 mt-24 mb-48">
        <div :class="`bg-${section}`" class="w-full  h-36 bg-web-application-development-section bg-no-repeat bg-contain"/>
        <div class="w-full  h-full flex flex-wrap gap-8 sm:gap-24 justify-evenly items-start 2xl:px-32">
          <div
              v-for="(item, index) in services[section]"
              :key="index"
              class="min-w-350p w-full sm:w-1/4 px-4 flex-col flex flex-wrap"
              data-aos="fade-down"
              data-aos-duration="1000"
              data-aos-easing="ease-out-cubic"
          >
            <span class="text-14 text-neutral/400 inline-block mb-2">{{ index >= 9 ? `${index+1}` : `0${index+1}` }}</span>
            <gradient-line class="mb-6"/>
            <div class="text-black font-bold text-24 text-left mb-3">{{ item.title }}</div>
            <div class="text-black font-normal text-16 text-left">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Services',
}
</script>

<script setup>
import { ref } from 'vue'
import GradientLine from '@/components/UI_components/GradientLine'
import PageHero from '@/components/PageHero'

const isOpenDropdown = ref(false)

const dropdownHandler = () => {
  isOpenDropdown.value = !isOpenDropdown.value
}

const tooltip = ref(null)

let services = {
  'web-application-development-section': [
    {
      title: 'CMS based Websites',
      description: 'Our developers have experience building websites for almost every industry and virtually every platform, including WordPress, Drupal, and Joomla.',
    },
    {
      title: 'Front-end Development\n',
      description: 'We work with modern frameworks like React.js, Angular, Vue.js, Nuxt, Next, and Gatsby to create next-generation user experiences that integrate and interact seamlessly with your database and APIs.',
    },
    {
      title: 'Back-end Development',
      description: 'Leverage our expertise with Cloud, DevOps, PHP, Node.js, Python, .NET, Java, and RoR to build custom, secure and reliable backends and APIs for web or mobile apps across multiple domains.',
    },
    {
      title: 'E-commerce Applications\n',
      description: 'If you have a store or need a new one, our web application development services can surely help you. We can create a store for you from scratch or leverage popular platforms such as WooCommerce, Shopify, or Magento.',
    },
    {
      title: 'Web Applications\n',
      description: 'As a web application development company, we have built all sorts of web applications, from online forms and workflows to whole management systems. We work on Projects both big and small.',
    },
    {
      title: 'Business Automation\n',
      description: 'Do you have a manual process that you need to automate? Our web application development team can develop a customized, secure, and scalable system as per your business needs',
    },
    {
      title: 'API Development and Documentation',
      description: 'We can help you if you need to build or consume an API. Our teams have worked with all sort of APIs, including Voice, Video, Payment, Cloud, Accounting, etc',
    },
    {
      title: 'Software Development\n',
      description: 'Our engineering team has a lot of experience developing complex web-based business systems with multiple users and roles and that connect with CRM, Accounting, Inventory, and Other Systems.',
    },
    {
      title: 'Dedicated Web Developer',
      description: 'Hiring dedicated Web developers remotely can help your business scale faster. You can hire dedicated Web developers 40h/week at a fixed monthly rate.',
    },
  ],
  'testing-qa-section': [
    {
      title: 'Functional Testing\n',
      description: 'Our extensively trained professionals ensure that the code function, security features, APIs, installation procedures and other functional aspects of a web/mobile app are compliant with the designed specifications and perfectly meet customer needs.\n',
    },
    {
      title: 'Performance Testing\n',
      description: 'Our QA experts carry out performance testing to determine your software’s stability, capacity, scalability, endurance, and speed under normal as well as anticipated peak load conditions, and detect elements that cause degradation.\n',
    },
    {
      title: 'Regression Testing\n',
      description: 'Our best-in-class regression testing services ensure that your apps remain defect-free even after modifications, patches, enhancements and configuration changes. We offer maximum test coverage to make sure that these changes do not hinder or impede the smooth functionality of existing applications.\n',
    },
    {
      title: 'Integration Testing\n',
      description: 'While working on your Projects, our QA specialists leverage top-down or bottom-up methods to test the integration of different modules within your app and ensure the seamless communication between all of them, while keeping in mind your budget and time constraints.\n',
    },
    {
      title: 'Security Testing\n',
      description: 'We focus on vulnerability testing, mobile, and web app penetration testing, ethical hacking, password cracking, adhoc data testing, and cross-site scripting to ensure your business and customers remain hack-proof.\n',
    },
    {
      title: 'Compatibility Testing\n',
      description: 'Our dedicated team of QA experts offer end-to-end compatibility testing services that include tests for operating systems, database, devices, network, versions, bandwidth handling capacity and browsers, to remove all bottlenecks from your software so that your users get the same positive experience, regardless of what their personal setup is.\n',
    },
    {
      title: 'Unit Testing\n',
      description: 'Our skilled testers come with the proficiency to detect bugs before the SDLC advances by writing superior quality unit tests, thereby, paving the way for less-pervasive re-factoring by the developers, and meeting short timelines.\n',
    },
    {
      title: 'Usability Testing\n',
      description: 'With our experienced QA professionals, get assured, defined and productive approaches for mobile app usability testing, A/B testing, design surveys, and preference testing, to make your app/website ready for a million users.',
    },
    {
      title: 'Automated Testing\n',
      description: 'Our test automation specialists have in-depth knowledge of multiple automation testing tools required to automate regression tests, develop new test cases and come up with automated test scripts. Our pool of skilled testers has the unmatched expertise to use scalable automation suites to test any app or API and get consistent quality and results.',
    },
  ],
  'mobile-section': [
    {
      title: 'Android Development',
      description: 'We know how to structure our work, how to communicate with business owners to get out the most essential details and compile consistent mobile solutions for your business.\n',
    },
    {
      title: 'iOS Development',
      description: 'We are certified engineers with proactive thoughts and a desire to offer you reliable services in delivering top-notch mobile applications.',
    },

  ],
  'custom-software-development-section': [
    {
      title: 'Advanced Web App',
      description: 'High-class specialists in our software development company handle the design, prototype, development, testing, and support of your advanced web applications by offering tailored solutions to unlock your business value.\n',
    },
    {
      title: 'Support and Maintenance',
      description: 'Fall back on our team of talented coders and engineers for software augmentation, technical snags, code optimization and restructuring, security audits, system stability, and other finer nuances to ensure that your software operates at high levels.\n',
    },
    {
      title: 'Ui and Ux Design',
      description: 'Are you looking to work with a team that puts forth a transparent design process and delivers spot-on results within budget? Turn to our long-standing design team specializing in corporate identity designs, responsive web solutions, print designs, motion graphics, and UX strategy and consulting.\n',
    },
    {
      title: 'SaaS Applications',
      description: 'We have extensive experience in offering custom-built SaaS-based app solutions that help us understand specific challenges in this space and deliver complex SaaS Projects on time and within budget.',
    },

  ],
  'outsource-section': [
    {
      title: 'MVP Development',
      description: 'We help startups and mature businesses with Minimum Viable Product (MVP) development services that include POC demo and prototype design, pilot MVP development and single feature MVP design that help organizations understand their product-market fit and allow them to grow without incurring unnecessary costs.\n',
    },
    {
      title: 'Product Transformation and Re-engineering\n',
      description: 'We bring forth cost-effective re-engineering and remodeling processes to help our clients replace their legacy-old product designs with newer and more cutting-edge architecture to maximize their business efficiency.\n',
    },
    {
      title: 'Software product Deployment',
      description: 'We leverage our industry experience to implement best-of-breed engineering solutions for deploying successful software products that thrive in this dynamic market and help our clients carve their niche.\n',
    },
    {
      title: 'Product Design and Engineering',
      description: 'Our certified designers and engineers come with the skills to map user flows, create top-notch product style-guides, sketches and wireframes to develop UI designs that ensure robustness and scalability, across platforms and devices.\n',
    },
    {
      title: 'Product Testing\n',
      description: 'Our dedicated and experienced QA engineers are proficient in offering test plans, test cases, risk assessment, defect analysis and recommendations to ensure smooth functionality, performance, adaptability, security, and usability of your software products while saving you considerable expense.\n',
    },
    {
      title: 'Product Support and Maintenance',
      description: 'Our knowledgeable consultants and strategists not only help businesses launch unique products with reduced time to market but also deliver maintenance services that include design enhancements, migration tasks, database and data warehouse support, bug tracking and fixing, functionality improvements, modification request management, new version releases and upgrades, off and on-site support, etc.',
    },

  ],
}

const switchTooltipAction = (e) => {
  tooltip.value.style.left = e.clientX+15+'px'
  tooltip.value.style.top = e.clientY+15+'px'
}

const switchTooltip = (e, type) => {
  if(type === 'show') {
    document.addEventListener('mousemove', switchTooltipAction)
  }else if(type === 'hide') {
    document.removeEventListener('mousemove', switchTooltipAction)
  }
}
</script>

<style>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.bg-service {
  background-image: url('~@/assets/backgrounds/service-background.png');
}
/*.text-shadow {
  text-shadow: 4px 2px 10px #686868;
}*/
@media (max-width: 1200px) {
  .navigation:hover .tooltip {
    display: block;
  }
}
</style>
