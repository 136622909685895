<template>
  <div class="w-full h-full flex flex-col pb-150p sm:pb-300p">
    <page-hero bg="projects-background">
      <template #title>
        From <span class="text-primary1">Idea</span> Into Excellent <span class="text-primary1">Result!</span>
      </template>
    </page-hero>

    <section class="pt-100p px-12 xl:px-100p flex items-center justify-center flex-wrap gap-12 lg:gap-24">
      <router-link :to="`/project-info/${project.id}`" v-for="project in showedCards" :key="project.id">
        <projects-item data-aos="flip-left" data-aos-duration="1000">
          <template #logo>
            <img :src="project.logo" class="w-3/5">
          </template>

          <template #brand>
            {{ project.brand }}
          </template>

          <template #short_description>
            {{ project['short-description'] }}
          </template>
        </projects-item>
      </router-link>
    </section>

    <div v-if="!isShowAllCards" class="flex justify-center">
      <custom-button type="secondary" class="mt-32" @buttonClick="switchShowedCurds">
        Load more
      </custom-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Projects',
}
</script>

<script setup>
import ProjectsItem from '@/components/ProjectInfo/ProjectsItem'
import { reactive, computed, ref } from 'vue'
import customButton from '@/components/UI_components/CustomButton'
import PageHero from "@/components/PageHero";
import projectsData from '@/store/projects.js'

const projects = reactive([...projectsData])

const isShowAllCards = ref(false)

const showedCards = computed(() => {
  if(isShowAllCards.value) {
    return projects
  }else{
    return projects.slice(0, 3)
  }
})

const switchShowedCurds = () => {
  isShowAllCards.value = true
}
</script>
<style>
.bg-projects-background {
  background-image: url('~@/assets/backgrounds/projects-background.png');
}
</style>

