<template>
  <main class="pt-112p lg:pb-112p">
    <div class="flex flex-col gap-12 justify-center items-center h-265p shadow-4xl z-20 mb-20 bg-navy text-white">
      <div class="text-32 font-bold tracking-wider">
        Terms and Conditions
      </div>

      <div class="text-neutral/500 font-bold">
        Updated 28/10/2022
      </div>
    </div>
    <section class="max-w-content m-auto pb-112p px-8 sm:px-16">
      <p class="leading-6 text-neutral/500">
        Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use,
        which together with our privacy policy govern 'Digital Threads’s relationship with you in relation to this website.
        If you disagree with any part of these terms and conditions, please do not use our website.
      </p>

      <p class="leading-6 text-neutral/500 mt-40p">
        The term 'Digital Threads's or ‘us’ or ‘we’ refers to the owner of the website whose registered office is Yerevan,
        st. Abelyan 6/4. The term ‘you’ refers to the user or viewer of our website.
      </p>


      <p class="leading-6 text-neutral/500 mt-40p">The use of this website is subject to the following terms of use:</p>
      <ol class="list-disc mr-20p ml-10 leading-6 text-neutral/500">
        <li>
          The content of the pages of this website is for your general information and use only. It is subject to change without notice.
        </li>
        <li>
          This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties:
        </li>
      </ol>
			<ol class="list-decimal mx-20p leading-6 text-neutral/500">
          <li>
            A randomly generated and unique number that is used to recognize your computer. This makes web applications such as online shops and online banking possible: The website "remembers" you accordingly, so that, for example, the previously selected goods do not disappear from your shopping basket every time you call up a new page.
          </li>
          <li>
            The domain name, i.e. the website to which the cookie refers. A website visit can also generate several cookies if, for example, image files are hosted on another server.
          </li>
          <li>
            User settings such as language and special preferences. The website operator does not want to force the user to adjust the website according to their needs each time they visit it. Therefore, such settings are stored by cookies.
          </li>
          <li>
            Time spent on the website or individual sub-pages. This data is collected for statistical evaluation.
          </li>
          <li>
            Data entered by the user via web forms – such as e-mail address, name, or telephone number. This also includes search terms entered in search masks.
          </li>
          <li>
            Visited sub-pages such as product pages in online shops. This data is highly relevant for online marketing.
          </li>
          <li>
            Meta data such as the expiry date or time of a cookie, the path, and the security specifications (e.g. “HTTPS only”). While some cookies are deleted after leaving the website (which is particularly common in online banking), other cookies remain for years.
          </li>
        </ol>
			<ol class="list-disc mr-20p ml-10 leading-6 text-neutral/500">
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </li>
        <li>
          Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
        </li>
        <li>
          This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
        </li>
        <li>
          All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
        </li>
        <li>
          Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
        </li>
        <li>
          From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
        </li>
      </ol>
    </section>
  </main>
</template>

<script>
export default {
  name: 'terms-and-conditions',
}
</script>
