import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Services from '@/views/Services'
import About from '@/views/About'
import Careers from '@/views/Careers'
import PageNotFound from '@/views/PageNotFound'
import Projects from '@/views/Projects'
import ContactUs from '@/views/ContactUs'
import ProjectInfo from '@/views/ProjectInfo'
import TermsAndConditions from '@/views/TermsAndConditions'
import privacyPolicy from '@/views/PrivacyPolicy'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title: 'Digital Threads - Home',
      background:'transparent'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta:{
      title: 'Digital Threads - Services',
      background:'white'
    }
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    meta:{
      title: 'Digital Threads - About',
      background:'white'
    }
  },
  {
    path: '/Careers',
    name: 'Careers',
    component: Careers,
    meta:{
      title: 'Digital Threads - Careers',
      background:'white'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta:{
      title: 'Digital Threads - Projects',
      background:'white'
    }
  },
  {
    path: '/project-info/:id',
    name: 'ProjectInfo',
    component: ProjectInfo,
    meta:{
      title: 'Digital Threads - Project Info',
      background:'white'
    }
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: ContactUs,
    meta:{
      title: 'Digital Threads - Contact Us',
      background:'white'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: PageNotFound,
    meta:{
      title: 'Digital Threads - Not Found',
      background:'white',
      hideFooter: false
    }
  },
  {
    path: '/privacy_policy',
    name: 'privacy-policy',
    component: privacyPolicy,
    meta:{
      title: 'Digital Threads - Privacy Policy',
      background:'white',
      hideFooter: false
    }
  },
  {
    path: '/terms_and_conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditions,
    meta:{
      title: 'Digital Threads - Terms and Conditions',
      background:'white',
      hideFooter: false
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if(to.hash === '#workflow') {
      document.getElementById(to.hash.slice(1)).scrollIntoView({ behavior: 'smooth' });
    } else if(to.hash === '#open-position') {
      document.getElementById(to.hash.slice(1)).scrollIntoView({ behavior: 'smooth' });
    } else {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle        = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta         = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
